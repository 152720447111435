<template>
  <TosListVuetify :entityName="entityName" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field :label="tLabel('codice')" v-model="slotProps.data.codice"  />
          </v-col>          
          <v-col cols="6">
            <v-select :label="tLabel('nodo')" v-model.number="slotProps.data.nodoId" :items="nodiWithAll" item-text="label" item-value="id"/>            
          </v-col>          
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="6">
            <v-text-field :label="tLabelRequired('codice')" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
          </v-col>
          <v-col cols="6">
            <v-text-field :label="tLabel('descrizione')" v-model="slotProps.data.descrizione"  />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field :label="tLabel('direzionePosizioneIniziale')" v-model="slotProps.data.direzionePosizioneIniziale" />
          </v-col>
          <v-col cols="6">
            <v-text-field :label="tLabel('direzionePosizioneFinale')" v-model="slotProps.data.direzionePosizioneFinale"  />
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
              <v-select :label="tLabelRequired('nodo')" v-model.number="slotProps.data.nodoId" :items="nodi" item-text="label" item-value="id" :rules="[rules.required]" :error-messages="slotProps.errors.nodo" />
            </v-col>
            <v-col cols="3">
            <v-checkbox :label="tLabel('default')" v-model="slotProps.data.parcoDefault" :error-messages="slotProps.errors.parcoDefault"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox :label="tLabel('extenal')" v-model="slotProps.data.external" :error-messages="slotProps.errors.external"/>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-slot:customButtons="slotProps">
        <v-btn icon title="Binari"  @click="showBinari(slotProps.data.id)">
          <v-icon color="primary" medium> mdi-link-variant</v-icon>
        </v-btn>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "ParcoBinari",
  data() {
    return {
      entityName: "parcoBinari",
      nodi: null,
      nodiWithAll: null,
      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "codice" },
        { text: "Descrizione", value: "descrizione" },
        { text: "Direzione posizione iniziale", value: "direzionePosizioneIniziale" },
        { text: "Direzione posizione finale", value: "direzionePosizioneFinale" },
        { text: "Nodo", value: "nodo", link: "true" },
        { text: "Default", value: "parcoDefault", formatValue:'formatBoolean' },
        { text: "External", value: "external", formatValue:'formatBoolean' },
      ]
    };
  },
  created() {
  },
  async mounted(){
    this.nodi = await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/shortList");
    this.nodiWithAll = [ {id: null, label: "Tutti"}, ...this.nodi ];
  },
  methods: {
    showBinari(id){
      this.$router.push({
          path: "/anagrafiche/binari/" + id,
        });
    },
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  mixins: [FormatsMixins],
};
</script>
